.logo {
    height: 28px;
    width: 295px;
}

/* tablets */
@media only screen and (max-width: 1200px) {

    .logo{
        width: 200px;
    }
}