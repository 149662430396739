.appBar {
    z-index: 2
}

.appBar {
    margin-bottom: 90px;
    background-color: transparent !important;
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
}

.appBarElevated{
    background-color: #0E2A5A !important;
}

.appBarElevated img{
    filter:  brightness(0) invert(1);
}

.toolbar {
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
}

.headerNav a {
    margin-right: 30px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 6px;
    /*background: #103576;*/
}

.headerNav a:hover {
    border: 1px solid #9cc8e7;
}

.authMenu{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.authMobileMenu{
    width: 100%;
    display: none;
    align-items: center;
    justify-content: right;
}

.authMobileMenu svg{
    color: #fff;
    font-size: 40px;
}

.authMobileMenuInner a{
    font-size: 20px;
    padding: 0px 20px;
    margin: 0px;
    justify-content: flex-start;
    text-transform: capitalize;
}

.logo {

}


.loginBtn{
    background-color: #fff;
    color: #0E2A5A;
    border: 2px solid #0E2A5A;
    text-transform: capitalize;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-left: 20px !important;
}

.signupBtn{
    color: #fff;
    border: 2px solid #fff;
    text-transform: capitalize;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-right: 0px;
}


/* mobile */
@media only screen and (max-width: 900px) {
    .authMenu {
        display: none;
    }
    .authMobileMenu{
        display: flex;
    }
}



/* tablets */
@media only screen and (max-width: 1260px) {
    .authMenu {
        /*display: none;*/
    }

    .headerNav a {
        margin-right: 5px;
        font-size: 17px;
    }

    .loginBtn, .signupBtn{
        font-size: 15px !important;
        border: 1px solid #fff;
    }

    .appBar{
        padding: 0px;
        margin-bottom: 0px;
    }
}
