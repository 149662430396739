.sideElement{
    position: absolute;
    width: 218px;
    height: 904px;
    z-index: 1;
    background-image: url('/images/side-element.svg');
    background-repeat: no-repeat;
}

.sideElementLeft{
    top: 30%;
    left: 0;
}

.sideElementRight{
    top: 60%;
    right: 0;
    transform: scaleX(-1);
}

/* tablets */
@media only screen and (max-width: 1260px) {
    .sideElementLeft{
        left: -100px;
    }

    .sideElementRight{
        width: 118px;
        background-position: -100px 0px;
    }
}

@media only screen and (max-width: 900px) {
    .sideElement{
        display: none;
    }
}