.footerContainer:before {
  width: 100%;
  height: 93px;
  background-image: url('/images/footer-top.svg');
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  top: -93px;
  background-size: cover;
}

.footerContainer {
  position: relative;
  width: 100%;
  background: #0F3371;
  background-repeat: no-repeat;
  margin-top: 80px;
}

.footerInnerContainer {
  position: relative;
  z-index: 1;
  color: #fff;
}

.footerInnerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding: 20px 0;
}

.footerCopyRights {
  text-align: center;
  color: #fff;
  //background: #0E2A5A;
  width: 100%;
  padding: 20px 0;
  background: linear-gradient(0deg, #112240, transparent);
}

.footerItem{
  display: flex;
  flex-direction: column;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;

  :global {
    .MuiButtonBase-root {
      color: #fff;

      svg {
        font-size: 50px;
      }
    }
  }
}

/* tablets */
@media only screen and (max-width: 1260px) {
  .footerInnerTop h6 {
    font-size: 20px !important;
  }

  .footerInnerTop p {
    font-size: 15px !important;
  }

  .footerInnerTop p,  .footerInnerTop a {
    font-size: 15px !important;
  }

  .footerInnerTop svg{
    font-size: 30px !important;
  }

  .footerInnerTop{
    padding: 20px;
    justify-content: space-evenly;
  }
}

/* mobiles */
@media only screen and (max-width: 900px) {
  .footerInnerTop{
    padding: 15px 30px;
    flex-direction: column;
    justify-content: center;

  }

  .footerItem {
    font-size: 20px !important;
    margin-bottom: 20px;
  }

  .socials {
    margin-top: 10px;
    justify-content: center;
  }

  .footerInnerTop h6 {
    font-size: 20px !important;
  }

  .footerInnerTop p {
    font-size: 18px !important;
  }

  .footerInnerTop p,  .footerInnerTop a {
    font-size: 18px !important;
  }

  .footerInnerTop svg{
    font-size: 50px !important;
  }

  .footerCopyRights{
    //width:100%;
    padding:10px 60px;
  }
}