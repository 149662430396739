@font-face {
    font-family: "Baloo Chettan 2 Bold";
    src: url('/fonts/Baloo_Chettan_2/static/BalooChettan2-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Baloo Chettan 2 Regular";
    src: url('/fonts/Baloo_Chettan_2/static/BalooChettan2-Regular.ttf') format('truetype');
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Baloo Chettan 2 Regular', sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

